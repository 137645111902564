(function ($) {
    let ajaxFunction = $.ajax;
    $.ajax           = function (params) {
        if ((_.isUndefined(params.hideLoader) || !params.hideLoader)
            && params.ajaxElement === 'undefined') {
            params.ajaxElement = $();
        }
        if (typeof EfbHelper !== 'undefined') {
            if (typeof params.error === "undefined") {
                params.error = function (parameters) {
                    EfbHelper.showErrorResult(params.ajaxElement, parameters);
                };
            }
            if (typeof params.complete === "undefined") {
                params.complete = function (parameters) {
                    EfbHelper.removeLoadingSpinner(params.ajaxElement);
                };
            }
            if (typeof params.beforeSend === "undefined") {
                params.beforeSend = function (parameters) {
                    EfbHelper.addLoadingSpinner(params.ajaxElement);
                };
            }
            let success    = params.success || function () {};
            params.success = function (response, statusText, xhr) {
                if (response.error_key === 'unauthorized') {
                    Intercom.getInstance().emit('pageReload');
                    return location.reload();
                }
                success.apply(this, arguments);
            };
            let error      = params.error || function () {};
            params.error   = function (xhr, textStatus, errorThrown) {
                if (xhr.status === 401) {
                    Intercom.getInstance().emit('pageReload');
                    return location.reload();
                }
                error.apply(this, arguments);
            };
        }
        return ajaxFunction.apply(this, arguments);
    };
    $.ucfirst        = function (str) {
        let parts    = str.split(' '),
            len      = parts.length,
            i, words = [];
        for (i = 0; i < len; i++) {
            let part  = parts[i];
            let first = part[0].toUpperCase();
            let rest  = part.substring(1, part.length);
            let word  = first + rest;
            words.push(word);

        }
        return words.join(' ');
    };
    let originalVal  = $.fn.val;
    $.fn.val         = function () {
        let oldVal;
        if (arguments.length > 0) {
            oldVal = $(this).val();
        }
        let result = originalVal.apply(this, arguments);
        if (arguments.length > 0 && oldVal != arguments[0]) {
            $(this).trigger('valueChange', arguments);
        }
        return result;
    };
    $.fn.pVal        = function () {
        let value;
        if (typeof this.attr('pure-value') === 'undefined') {
            value = this.val();
            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                value = parseFloat(value);
            }
            return arguments.length ? this.val(arguments[0]) : value;
        }
        if (arguments.length) {
            value = arguments[0];
            if (value === null) {
                return this.attr('pure-value', '').val(value);
            }
            value = parseFloat(value);
            if (isNaN(value)) {
                value = 0;
            }
            this.attr('pure-value', value);
            if (!_.isUndefined(this.data('format'))) {
                value = $.sprintf(this.data('format'), value);
            } else if (!_.isUndefined(this.attr('precision'))) {
                value = parseFloat(value.toFixed(parseInt(this.attr('precision')))).toString();
            } else {
                value = Math.round(value);
            }
            return this.attr('value', value).val(value);
        }
        value = this.attr('pure-value');
        if ($.trim(value) === '' || isNaN(parseFloat(value)) || !isFinite(value)) {
            return null;
        }
        return parseFloat(value);
    };

    $.fn.uVal = function () {
        let data = this.data();
        if (!data || !data.unitBase) {
            return arguments.length ? this.pVal(arguments[0]) : this.pVal();
        }
        let convert = new MathConvert();
        if (arguments.length) {
            convert.setValue(this, arguments[0]);
            return this;
        }
        return convert.getValue(this);
    };

    $.fn.destroyChosen = function () {
        try {
            this.chosen('destroy');
        } catch (e) {

        }
        this.next('div.chosen-container').remove();
        this.removeAttr('style');
        this.prev('div.formelement').css('width', '');
        return this;
    };

    $.fn.hiddenChosen = function () {
        let selectElement       = this;
        let intervalDelay       = typeof arguments[1] === 'number' ? arguments[1] : 200;
        let options             = arguments[0] || {};
        options.disable_search  = false;
        options.no_results_text = 'N/A';
        if (this.length > 1) {
            $(this).each(function (i, select) {
                $(select).hiddenChosen($.extend({}, options), intervalDelay)
            });
            return this;
        }
        if (!selectElement.is('select')) {
            return this;
        }
        let parent     = selectElement.parent();
        let width, pWidth, pHeight;
        let addButtons = chosen => {
            if (!chosen) {
                return;
            }
            let container = chosen.buttonContainer.empty();
            let buttons   = Array.isArray(chosen.options.buttons) ? chosen.options.buttons : [];
            chosen.container.toggleClass('hasActions', !!buttons.length);
            buttons.forEach(button => {
                if (typeof button !== 'object' || typeof button.func !== 'function') {
                    return;
                }
                button.label = button.label || '';
                let aB       = button.button ? $(button.button) : $('<a/>').html(button.label);
                aB.click(e => {button.func(e, chosen)});
                container.append(aB);
            });
        };
        //selectElement.css('width', width);
        let chosen;
        selectElement.unbind('chosen:ready.init').on('chosen:ready.init', function (evt, params) {
            //setTimeout(function () {selectElement.hide()}, 1);
            chosen = params.chosen;
            if (!chosen.is_multiple) {
                chosen.container.unbind('mousedown.chosen').unbind('mouseup.chosen');
                chosen.container.on('click.chosen', (function (_this) {
                    return function (evt) {
                        _this.container_mousedown(evt);
                    };
                })(chosen));
            }
            params.parentElement = parent;
            parent.addClass('chosen');
            parent.css({'width': pWidth});
            parent.data('height', pHeight);
            if (!chosen.search_field) {
                return;
            }
            let time            = 0;
            let buttonContainer = $('<div/>', {class: 'chosen-buttons'});
            chosen.dropdown.prepend(buttonContainer);
            chosen.buttonContainer = buttonContainer;
            addButtons(chosen);
            chosen.search_field.on('focusin.openOnFocus', function () {
                if (new Date().getTime() - time < 10) {
                    return;
                }
                time = new Date().getTime();
                selectElement.trigger('chosen:open', true);

            });
            // chosen.search_field.attr('tabindex','1');
            chosen.search_field.unbind('keydown.tabNext').on('keydown.tabNext', function (e) {
                selectElement.get(0).dispatchEvent(new KeyboardEvent(e.type, {key: e.key, shiftKey: e.shiftKey}))
                if (e.key !== "Tab") {
                    return;
                }
                // chosen.search_field.emulateTab(1);
                let nextFormElement = parent.next('div.formelement');
                if (!nextFormElement.length) {
                    nextFormElement = parent.next().find('div.formelement');
                }
                if (nextFormElement.hasClass('chosen')) {
                    nextFormElement.find('select').trigger('chosen:open');
                } else {
                    nextFormElement.find(':input').first().focus();
                }
            });
        }).unbind('chosen:updated.addButtons').on('chosen:updated.addButtons', function (evt) {
            addButtons($(evt.target).data('chosen'));
        });
        let sData = selectElement.data() || {};
        Object.keys(sData).forEach(function (key) {
            if (key.indexOf('chosen_') !== 0) {
                return;
            }
            let value     = sData[key];
            let oKey      = key.replace(/^chosen_/, '');
            options[oKey] = value;
        });
        let destroy    = function (select) {
            select.destroyChosen();
        };
        let initChosen = function (select) {
            if (select.is(':reallyvisible')) {
                pWidth  = pWidth || parent.outerWidth();
                pHeight = parent.outerHeight();
                width   = selectElement.outerWidth();
                if (!selectElement.width()) {
                    width = pWidth;
                }
                destroy(select);
                if (parent.hasClass('full-width')) {
                    pWidth = width = '100%';
                }
                select.css('width', width);
                select.chosen(options);
                return true;
            }
            return false;
        };
        if (!initChosen(selectElement)) {
            let intervalId = setInterval(function () {
                if (initChosen(selectElement)) {
                    clearInterval(intervalId);
                }
            }, intervalDelay);
        }
        selectElement.on('chosen:showing_dropdown', function (evt, params) {
            let chosen = params.chosen;
            if (chosen.is_multiple) {
                $(chosen.dropdown.css({paddingTop: parent.height() + 10}))
                parent.css({'min-height': parent.data('height')});
                return;
            }
            ['.within', '.inner-content', 'body'].every(selector => {
                if (!chosen.options.within || !chosen.options.within.length) {
                    chosen.options.within = chosen.container.parents(selector).first();
                }
                return !chosen.options.within.length;
            })
            if (!chosen.options.within.length) {
                return;
            }
            let targetWidth = chosen.dropdown.outerWidth();
            let wo          = chosen.options.within.offset();
            let co          = chosen.container.offset();
            wo.right        = wo.left + chosen.options.within.outerWidth();
            co.right        = co.left + chosen.dropdown.outerWidth(true);
            if (co.right > wo.right) {
                targetWidth -= co.right - wo.right;
            }
            chosen.container.outerWidth(targetWidth - 1);
            chosen.dropdown.outerWidth(targetWidth + 1);
            parent.css({'min-height': parent.data('height')});
            // chosen.container.css({'zIndex': 200, 'position': 'absolute'});
            try {
                let scrollEl = chosen.dropdown.get(0);
                if (scrollEl.scrollIntoViewIfNeeded) {
                    scrollEl.scrollIntoViewIfNeeded(true)
                } else {
                    scrollEl.scrollIntoView(true);
                }
            } catch (e) {

            }
        }).on('chosen:hiding_dropdown', function (evt, params) {
            let parent = params.chosen.container.parent();
            if (parent.hasClass('custom-field')) {
                return;
            }
            if (params.chosen.options.multiple) {
                return;
            }
            params.chosen.container.removeAttr('style');
            params.chosen.dropdown.removeAttr('style');
            parent.css({'height': ''});
            params.chosen.container.width(parent.outerWidth());
        }).on('change.updateChosen', function () {
            if (chosen && chosen.is_multiple) {
                chosen.results_build();
                chosen.results_show();
            }
        });
        return this;
    };

    let addClass  = $.fn.addClass;
    $.fn.addClass = function () {
        let result = addClass.apply(this, arguments);
        $(this).trigger('cssClassChanged');
        return result;
    };

    let removeClass  = $.fn.removeClass;
    $.fn.removeClass = function () {
        let result = removeClass.apply(this, arguments);
        $(this).trigger('cssClassChanged');
        return result;
    };


    let attrFunction           = $.fn.attr;
    $.fn.attr                  = function () {
        let editArguments = arguments;
        if (editArguments.length > 1) {
            editArguments               = {};
            editArguments[arguments[0]] = arguments[1];
            editArguments               = [editArguments];

        }
        if (!_.isUndefined(editArguments[0]) && !_.isUndefined(editArguments[0]['readonly'])) {
            if (editArguments[0]['readonly'] === true || typeof editArguments[0]['readonly'] === 'string') {
                editArguments[0]['tabindex'] = '-1';
            } else {
                $(this).removeAttr('tabindex');
            }
        }
        return attrFunction.apply(this, editArguments);
    };
    let removeAttrFunction     = $.fn.removeAttr;
    $.fn.removeAttr            = function () {
        let element = removeAttrFunction.apply(this, arguments);
        if (!_.isUndefined(arguments[0]) && arguments[0] === 'readonly') {
            element = removeAttrFunction.apply(element, ['tabindex']);
        }
        return element;
    };
    let serializeArrayFunction = $.fn.serializeArray;
    $.fn.serializeArray        = function () {
        let $container    = $(this).eq(0);
        let $textElements = $container.find(
            "input[type=text][data-unit-view],input[type=hidden][data-unit-view]," +
            "input[type=text][pure-value]"
        )
            .each(function (i, el) {
                el = $(el);
                el.attr('display-value', el.val());
                if (!_.isUndefined(el.attr('data-unit-view'))) {
                    try {
                        el.val(new MathConvert().getValue(el));
                    } catch (e) {
                        el.val(el.attr('display-value'));
                    }
                } else {
                    el.val(el.pVal());
                }

            });
        let s             = serializeArrayFunction.apply(this, arguments);
        $textElements.each(function (i, el) {
            el = $(el);
            el.val(el.attr('display-value'));
            el.removeAttr('display-value');
        });
        return s;
    };
    let originalOn             = $.fn.on;
    $.fn.on                    = function () {
        let typesString = arguments[0];
        let elements    = this;
        let args        = arguments;
        if (_.isObject(typesString)) {
            return originalOn.apply(this, arguments);
        }
        if (typesString.indexOf('blurEnter') !== -1) {
            let types = [];
            // find function in arguments
            let callBack, callBackIndex;
            $.each(args, function (i, arg) {
                if (typeof arg === 'function') {
                    callBack      = arg;
                    callBackIndex = i;
                }
            });
            typesString.split(' ').forEach(function (type) {
                args[callBackIndex] = callBack;
                if (type.indexOf('blurEnter') !== -1) {
                    types.push(type.replace('blurEnter', 'blur'));
                    args[0]             = type.replace('blurEnter', 'keypress');
                    args[0] += arguments[0].indexOf('.') !== -1 ? 'BE1234567' : '.BE1234567';
                    args[callBackIndex] = function () {
                        let event = arguments[0];
                        if (event.keyCode === 13) {
                            callBack.apply(this, arguments);
                        }
                    };
                    originalOn.apply(elements, args);

                } else {
                    types.push(type);
                }
            });
            args[callBackIndex] = callBack;
            args[0]             = types.join(' ');
        }
        return originalOn.apply(this, args);
    };
    let originalUnbind         = $.fn.unbind;
    $.fn.unbind                = function () {
        let typesString = arguments[0];
        let types       = [];
        if (typesString.indexOf('blurEnter') !== -1) {
            typesString.split(' ').forEach(function (type) {
                if (type.indexOf('blurEnter') !== -1) {
                    types.push(type.replace('blurEnter', 'blur'));
                    type = type.replace('blurEnter', 'keypress');
                    type += type.indexOf('.') !== -1 ? 'BE1234567' : '.BE1234567';
                    types.push(type);

                } else {
                    types.push(type);
                }
            });
            arguments[0] = types.join(' ');
        }
        return originalUnbind.apply(this, arguments);
    };

    let datePicker  = $.fn.datepicker;
    $.fn.datepicker = function (params) {
        if (typeof params === 'string') {
            return datePicker.apply(this, arguments);
        }
        let beforeShow    = params.beforeShow || function () {};
        let onClose       = params.onClose || function () {};
        params.showAnim   = null;
        params.duration   = 0;
        params.beforeShow = function (input, instance) {
            input = $(input);
            instance.dpDiv.addClass('v-hidden');
            let formElement = input.parent('.formelement');
            let posClass    = ['ui-datepicker-open'];
            setTimeout(() => {
                let inputTop = input.get(0).getBoundingClientRect().top;
                let dpTop    = instance.dpDiv.get(0).getBoundingClientRect().top;
                let topOffset;
                if (inputTop > dpTop) {
                    topOffset = -15;
                    posClass.push('datepicker-open-on-top');
                } else {
                    posClass.push('datepicker-open-on-bottom');
                    topOffset = 5;
                }
                let inPos = input.offset();
                let dpPos = instance.dpDiv.offset();
                if (params.appendTo) {
                    instance.dpDiv.appendTo(params.appendTo);
                    let leftDiff = dpPos.left - inPos.left;
                    let position = {
                        my:        'left' + (leftDiff < 0 ? '-' : '+') + Math.abs(leftDiff).toString() + 'px ' + (inputTop > dpTop ? 'bottom' : 'top'),
                        at:        'left ' + (inputTop > dpTop ? 'top-25px' : 'bottom+15px'),
                        of:        input,
                        collision: 'none',
                    };
                    instance.dpDiv.position(position);
                    instance.dpDiv.removeClass('v-hidden');
                } else {
                    dpPos.top += topOffset;
                    instance.dpDiv.css('top', dpPos.top).removeClass('v-hidden');
                }
                formElement.addClass(posClass.join(' '));
            }, 10)
            return beforeShow.apply(this, arguments);
        }
        params.onClose    = function (txt, instance) {
            let formElement = $(instance.input).parent('.formelement');
            formElement.removeClass('ui-datepicker-open datepicker-open-on-top datepicker-open-on-bottom');
            instance.dpDiv.appendTo('body');
            return onClose.apply(this, arguments);
        }
        return datePicker.apply(this, arguments);
    };

    $.fn.getAttributes = function () {
        let attributes = {};

        if (this.length) {
            $.each(this[0].attributes, function (index, attr) {
                attributes[attr.name] = attr.value;
            });
        }

        return attributes;
    };
    // disable button function
    $.fn.button        = function () {
        return this;
    };
})(jQuery);


jQuery.extend(
    jQuery.expr[":"],
    {
        reallyvisible: function (a) {
            return !(jQuery(a).is(':hidden') || jQuery(a).parents(':hidden').length);
        }
    }
);
// jQuery.extend(
//     $.datepicker, {
//         _checkOffset: function (inst, offset, isFixed) {
//             return offset
//         }
//     }
// );
jQuery.extend({
    keys:   function (obj) {
        let a = [];
        $.each(obj, function (k) {
            a.push(k)
        });
        return a;
    },
    values: function (obj) {
        let a = [];
        $.each(obj, function (k, v) {
            a.push(v)
        });
        return a;
    }
});
jQuery.extend({
    keyCount: function (o) {
        if (typeof o == "object") {
            let i, count = 0;
            for (i in o) {
                if (o.hasOwnProperty(i)) {
                    count++;
                }
            }
            return count;
        } else {
            return false;
        }
    }
});

$.propHooks.checked = {
    set: function (elem, value, name) {
        let ret = (elem[name] = value);
        $(elem).trigger("change");
        return ret;
    }
};

jQuery.nl2br = function (varTest) {
    return varTest.replace(/(\r\n|\n\r|\r|\n)/g, "<br>");
};

Math.fmod = function (a, b) { return Number((a - (Math.floor(a / b) * b)).toPrecision(8)); };


jQuery.fn.extend({
    renameAttr: function (name, newName, removeData) {
        let val;
        return this.each(function () {
            val = jQuery.attr(this, name);
            jQuery.attr(this, newName, val);
            jQuery.removeAttr(this, name);
            // remove original data
            if (removeData !== false) {
                jQuery.removeData(this, name.replace('data-', ''));
            }
        });
    }
});


Object.resolve = function (path, obj, fallBack) {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : fallBack
    }, obj)
};

Object.getPath = function (haystack, needle) {
    if (haystack.constructor !== Object && haystack.constructor !== Array) {
        throw new TypeError('getPath() can only operate on object with Object as constructor');
    }
    let object = haystack;
    let path   = [];
    if (haystack === needle) {
        return path;
    }
    if (_.isArray(object)) {
        object.forEach((o, index) => {
            let p = Object.getPath(o, needle);
            if (!p) {
                return;
            }
            path.push(index);
            p.forEach(p => {path.push(p)});
        })
    } else if (_.isObject(object)) {
        Object.keys(object).forEach((key) => {
            if (object[key] === needle) {
                path.push(key);
                return path;
            }
            if (_.isObject(object[key])) {
                let p = Object.getPath(object[key], needle);
                if (p) {
                    path.push(key);
                    p.forEach(p => {path.push(p)});
                }
            }
        });
    }
    return path.length ? path : null;
};


function parse_str(str, array) {
    //       discuss at: http://phpjs.org/functions/parse_str/
    //      original by: Cagri Ekin
    //      improved by: Michael White (http://getsprink.com)
    //      improved by: Jack
    //      improved by: Brett Zamir (http://brett-zamir.me)
    //      bugfixed by: Onno Marsman
    //      bugfixed by: Brett Zamir (http://brett-zamir.me)
    //      bugfixed by: stag019
    //      bugfixed by: Brett Zamir (http://brett-zamir.me)
    //      bugfixed by: MIO_KODUKI (http://mio-koduki.blogspot.com/)
    // reimplemented by: stag019
    //         input by: Dreamer
    //         input by: Zaide (http://zaidesthings.com/)
    //         input by: David Pesta (http://davidpesta.com/)
    //         input by: jeicquest
    //             note: When no argument is specified, will put variables in global scope.
    //             note: When a particular argument has been passed, and the returned value is different parse_str of PHP. For example, a=b=c&d====c
    //             test: skip
    //        example 1: let arr = {};
    //        example 1: parse_str('first=foo&second=bar', arr);
    //        example 1: $result = arr
    //        returns 1: { first: 'foo', second: 'bar' }
    //        example 2: let arr = {};
    //        example 2: parse_str('str_a=Jack+and+Jill+didn%27t+see+the+well.', arr);
    //        example 2: $result = arr
    //        returns 2: { str_a: "Jack and Jill didn't see the well." }
    //        example 3: let abc = {3:'a'};
    //        example 3: parse_str('abc[a][b]["c"]=def&abc[q]=t+5');
    //        returns 3: {"3":"a","a":{"b":{"c":"def"}},"q":"t 5"}

    let strArr = String(str)
            .replace(/^&/, '')
            .replace(/&$/, '')
            .split('&'),
        sal    = strArr.length,
        i, j, ct, p, lastObj, obj, lastIter, undef, chr, tmp, key, value,
        postLeftBracketPos, keys, keysLen,
        fixStr = function (str) {
            return decodeURIComponent(str.replace(/\+/g, '%20'));
        };

    if (!array) {
        array = this.window;
    }

    for (i = 0; i < sal; i++) {
        tmp   = strArr[i].split('=');
        key   = fixStr(tmp[0]);
        value = (tmp.length < 2) ? '' : fixStr(tmp[1]);

        while (key.charAt(0) === ' ') {
            key = key.slice(1);
        }
        if (key.indexOf('\x00') > -1) {
            key = key.slice(0, key.indexOf('\x00'));
        }
        if (key && key.charAt(0) !== '[') {
            keys               = [];
            postLeftBracketPos = 0;
            for (j = 0; j < key.length; j++) {
                if (key.charAt(j) === '[' && !postLeftBracketPos) {
                    postLeftBracketPos = j + 1;
                } else if (key.charAt(j) === ']') {
                    if (postLeftBracketPos) {
                        if (!keys.length) {
                            keys.push(key.slice(0, postLeftBracketPos - 1));
                        }
                        keys.push(key.substr(postLeftBracketPos, j - postLeftBracketPos));
                        postLeftBracketPos = 0;
                        if (key.charAt(j + 1) !== '[') {
                            break;
                        }
                    }
                }
            }
            if (!keys.length) {
                keys = [key];
            }
            for (j = 0; j < keys[0].length; j++) {
                chr = keys[0].charAt(j);
                if (chr === ' ' || chr === '.' || chr === '[') {
                    keys[0] = keys[0].substr(0, j) + '_' + keys[0].substr(j + 1);
                }
                if (chr === '[') {
                    break;
                }
            }

            obj = array;
            for (j = 0, keysLen = keys.length; j < keysLen; j++) {
                key      = keys[j].replace(/^['"]/, '')
                    .replace(/['"]$/, '');
                lastIter = j !== keys.length - 1;
                lastObj  = obj;
                if ((key !== '' && key !== ' ') || j === 0) {
                    if (obj[key] === undef) {
                        obj[key] = {};
                    }
                    obj = obj[key];
                } else { // To insert new dimension
                    ct = -1;
                    for (p in obj) {
                        if (obj.hasOwnProperty(p)) {
                            if (+p > ct && p.match(/^\d+$/g)) {
                                ct = +p;
                            }
                        }
                    }
                    key = ct + 1;
                }
            }
            lastObj[key] = value;
        }
    }
}

jQuery.support.cors        = true;
Date.prototype.getUnixTime = function () { return this.getTime() / 1000 | 0 };

let is9767U = _.isUndefined;

_.isUndefined = function (object, path) {
    if (typeof path !== 'string') {
        return is9767U(object);
    }
    let isUndefined = false;
    path.split('.').every(function (p) {
        object      = $.extend({}, object);
        isUndefined = is9767U(object[p]);
        object      = object[p];
        return !isUndefined;
    });
    return isUndefined;
};

Promise.series = function (providers, initial) {
    if (!Array.isArray(providers)) {
        return Promise.reject(new TypeError('promise.series only accepts an array of functions'));
    }
    return providers.reduce(function (current, next) {
        return current.then(next);
    }, Promise.resolve(initial));
};


/**
 *
 * @param haystack
 * @param needle
 * @param mode
 * @returns {boolean}
 */
_.inArray = function (haystack, needle, mode) {
    mode        = [undefined, 'or', 'and'].indexOf(mode) === -1 ? undefined : mode;
    needle      = !mode ? [needle] : needle;
    let inArray = false;
    needle.every(function (n) {
        inArray = haystack.indexOf(n) !== -1;
        return mode === 'and' ? inArray : !inArray;
    });
    return inArray;
};


if (typeof (Number.prototype.isBetween) === "undefined") {
    Number.prototype.isBetween = function (num1, num2, notBoundaries) {
        let num     = [num1, num2].sort(function (a, b) {return a - b});
        let between = false;
        if (notBoundaries) {
            if ((this < num[1]) && (this > num[0])) between = true;
        } else {
            if ((this <= num[1]) && (this >= num[0])) between = true;
        }
        return between;
    }
}